











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TooltipComponent extends Vue {
  @Prop() public tooltipText: any;
}
