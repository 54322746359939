















































































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import TotalComponent from './TotalComponent.vue';
import Highcharts, { chart } from "highcharts";
import ReportDataTableComponent from "../components/ReportDataTableComponent.vue";
import SimpleChartComponent from '../components/SimpleChartComponent.vue';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import TotalsItemComponent from '../components/TotalsItemComponent.vue';
import { StackedBarMeta, BulletGraphMeta, TotalsItem, TotalsAggregate, ImpactTargetsMeta, ReportDataTable, TotalsAggregateValueEmployment, OccupationDiversityMeta, ReportDataTableRow } from '../store/models';
import { EmptyStackedBarMeta, EmptyBulletGraphMeta, EmptyTotalsItem, EmptyReportDataTable, EmptyReportDataTableRow } from '../store/models-empty';
import loadBullet from "highcharts/modules/bullet";
import * as colors from '../lib/colors';
import * as charts from '../lib/charts';
import * as _ from 'lodash';
import { formatAmountToString, formatCurrencyToString } from '@/lib/shared';

loadBullet(Highcharts);

@Component({
  components: {
    TotalComponent,
    SimpleChartComponent,
    SpinnerComponent,
    TotalsItemComponent,
    ReportDataTableComponent,
  },
})
export default class WorkforceDiversityComponent extends BaseComponent {

  @Prop() public aggregate: any;
  @Prop() public impactTargets: any;
  @Prop() public reportHelper: any;
  @Prop() public projectId: any;
  @Prop() public theme: any;
  @Prop() public hideTitle: any;
  @Prop() public reportMode: any;

  private chartDataHours: any = {};
  private chartDataWages: any = {};
  private diverseChartData: any = {};
  private workforceDiversityItems: any = [];
  private workforceDiversityTotals: any = {};
  private reportSummaryTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private reportOccupationByDiversityHoursTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private reportOccupationByDiversityHourlyRateTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);

  private diverseTotalsItem: TotalsItem = _.cloneDeep(EmptyTotalsItem);
  private diverseTarget: number = 0;

  private isLoaded: boolean = false;

  protected mounted() {
    this.refreshCharts();
  }

  private getTargets() {
    const impactTargets = this.impactTargets as ImpactTargetsMeta;
    this.diverseTarget = this.zeroIfUndefined(impactTargets.indigenousEmployment);
  }

  private refreshCharts() {
    if (this.aggregate) {
      // console.log(JSON.stringify(this.aggregate));
      this.getTargets();
      const totalsAggregate = (this.aggregate as TotalsAggregate);
      const chartMetaHours: StackedBarMeta = _.cloneDeep(EmptyStackedBarMeta);
      chartMetaHours.categories = [ '' ];
      const series: any[] = [];
      series.push({ name: 'Indigenous Workers', index: 3, stack: 'stack1', data: [ totalsAggregate.employment.indigenous.hours.percentage ], color: colors.colorIndigenousEmployment });
      series.push({ name: 'Undeclared / Unknown Workers', index: 2, stack: 'stack1', data: [ totalsAggregate.employment.unknownAndNoAnswer.hours.percentage ], color: colors.colorNonDeclaredEmployment });
      series.push({ name: 'Equity Seeking Workers (Less Indigenous)', index: 1, stack: 'stack1', data: [ totalsAggregate.employment.diverse.hours.percentage ], color: colors.colorDiverseEmployment });
      series.push({ name: 'Non Equity Seeking Workers', index: 0, stack: 'stack1', data: [ totalsAggregate.employment.diverseNo.hours.percentage ], color: colors.colorNonDiverseEmployment });

      chartMetaHours.series = series;
      chartMetaHours.totalStacks = 1;
      chartMetaHours.formatNumbersToCurrency = false;
      chartMetaHours.legendEnabled = true;
      chartMetaHours.height = 235;
      chartMetaHours.legendColor = '#FFFFFF';
      chartMetaHours.bgColor = colors.colorEmployment;
      chartMetaHours.exportingEnabled = true;
      chartMetaHours.isPercentage = true;
      chartMetaHours.isCurrency = false;
      chartMetaHours.yAxisVisible = false;

      this.chartDataHours = charts.getStackedBarFromMeta(chartMetaHours, null, null, 50);

      const chartMetaWages: StackedBarMeta = _.cloneDeep(EmptyStackedBarMeta);
      chartMetaWages.categories = [ '' ];
      const seriesWages: any[] = [];
      seriesWages.push({ name: 'Indigenous Workers', index: 3, stack: 'stack1', data: [ totalsAggregate.employment.indigenous.wages.value ], color: colors.colorLightIndigenousEmployment });
      seriesWages.push({ name: 'Undeclared / Unknown Workers', index: 2, stack: 'stack1', data: [ totalsAggregate.employment.unknownAndNoAnswer.wages.value ], color: colors.colorLightNonDeclaredEmployment });
      seriesWages.push({ name: 'Equity Seeking Workers (Less Indigenous)', index: 1, stack: 'stack1', data: [ totalsAggregate.employment.diverse.wages.value ], color: colors.colorLightDiverseEmployment });
      seriesWages.push({ name: 'Non Equity Seeking Workers', index: 0, stack: 'stack1', data: [ totalsAggregate.employment.diverseNo.wages.value ], color: colors.colorLightNonDiverseEmployment });

      chartMetaWages.series = seriesWages;
      chartMetaWages.totalStacks = 1;
      chartMetaWages.formatNumbersToCurrency = true;
      chartMetaWages.legendEnabled = true;
      chartMetaWages.height = 235;
      chartMetaWages.legendColor = colors.colorDarkBlue;
      chartMetaWages.bgColor = colors.colorLightGrey;
      chartMetaWages.exportingEnabled = true;
      chartMetaWages.isPercentage = false;
      chartMetaWages.isCurrency = true;
      chartMetaWages.yAxisVisible = false;

      this.chartDataWages = charts.getStackedBarFromMeta(chartMetaWages, null, null, 50);

      const diverseChartMeta: BulletGraphMeta = _.cloneDeep(EmptyBulletGraphMeta);
      const diverseValue = totalsAggregate.employment.indigenousAndDiverse.hours.percentage;
      this.diverseTotalsItem.description = 'TOTAL DIVERSITY OF EMPLOYMENT';
      this.diverseTotalsItem.percentageFormatted = totalsAggregate.employment.indigenousAndDiverse.hours.percentageFormatted;
      this.diverseTotalsItem.percentageDescription = 'of Total Employment Hours';
      this.diverseTotalsItem.amount = totalsAggregate.employment.indigenousAndDiverse.hours.value;
      this.diverseTotalsItem.isFullWidth = true;
      this.diverseTotalsItem.color = colors.colorIndigenousEmployment;
      this.diverseTotalsItem.backgroundColor = colors.colorWhite;
      this.diverseTotalsItem.isCurrency = false;
      this.diverseTotalsItem.isSmallPercentage = true;
      diverseChartMeta.marginLeft = 10;
      diverseChartMeta.exportingEnabled = false,
      diverseChartMeta.height = 90;
      diverseChartMeta.theme = this.theme;
      diverseChartMeta.legendColor = colors.colorIndigenousEmployment;
      const plotBands: any = [{
        from: 0,
        to: 100,
        color: colors.colorWhite,
      }];
      diverseChartMeta.series = [{
        data: [{
          y: diverseValue,
          target: this.diverseTarget,
          color: colors.colorIndigenousEmployment,
        }],
      }];
      if (diverseValue > 0 || this.diverseTarget) {
        diverseChartMeta.plotBands = plotBands;
      }
      this.diverseChartData = charts.getBulletGraphFromMeta(diverseChartMeta);
      this.workforceDiversityItems.push({ workerCategory: 'Indigenous Workers',
                                          noOfWorkers: totalsAggregate.employment.indigenous.headCount.valueFormatted + ' (' + totalsAggregate.employment.indigenous.headCount.percentageFormatted + ')',
                                          totalHours: totalsAggregate.employment.indigenous.hours.valueFormatted + ' (' + totalsAggregate.employment.indigenous.hours.percentageFormatted + ')',
                                          totalWages: totalsAggregate.employment.indigenous.wages.value,
                                          avgWagePerHour: (totalsAggregate.employment.indigenous.wages.value > 0 && totalsAggregate.employment.indigenous.hours.value > 0) ? totalsAggregate.employment.indigenous.wages.value / totalsAggregate.employment.indigenous.hours.value : 0,
                                        });
      this.workforceDiversityItems.push({ workerCategory: 'Equity Seeking Workers (Less Indigenous)',
                                          noOfWorkers: totalsAggregate.employment.diverse.headCount.valueFormatted + ' (' + totalsAggregate.employment.diverse.headCount.percentageFormatted + ')',
                                          totalHours: totalsAggregate.employment.diverse.hours.valueFormatted + ' (' + totalsAggregate.employment.diverse.hours.percentageFormatted + ')',
                                          totalWages: totalsAggregate.employment.diverse.wages.value,
                                          avgWagePerHour: (totalsAggregate.employment.diverse.wages.value > 0 && totalsAggregate.employment.diverse.hours.value > 0) ? totalsAggregate.employment.diverse.wages.value / totalsAggregate.employment.diverse.hours.value : 0,
                                        });
      this.workforceDiversityItems.push({ workerCategory: 'Non Equity Seeking Workers',
                                          noOfWorkers: totalsAggregate.employment.diverseNo.headCount.valueFormatted + ' (' + totalsAggregate.employment.diverseNo.headCount.percentageFormatted + ')',
                                          totalHours: totalsAggregate.employment.diverseNo.hours.valueFormatted + ' (' + totalsAggregate.employment.diverseNo.hours.percentageFormatted + ')',
                                          totalWages: totalsAggregate.employment.diverseNo.wages.value,
                                          avgWagePerHour: (totalsAggregate.employment.diverseNo.wages.value > 0 && totalsAggregate.employment.diverseNo.hours.value > 0) ? totalsAggregate.employment.diverseNo.wages.value / totalsAggregate.employment.diverseNo.hours.value : 0,
                                        });
      this.workforceDiversityItems.push({ workerCategory: 'Undeclared / Unknown Workers',
                                          noOfWorkers: totalsAggregate.employment.unknownAndNoAnswer.headCount.valueFormatted + ' (' + totalsAggregate.employment.unknownAndNoAnswer.headCount.percentageFormatted + ')',
                                          totalHours: totalsAggregate.employment.unknownAndNoAnswer.hours.valueFormatted + ' (' + totalsAggregate.employment.unknownAndNoAnswer.hours.percentageFormatted + ')',
                                          totalWages: totalsAggregate.employment.unknownAndNoAnswer.wages.value,
                                          avgWagePerHour: (totalsAggregate.employment.unknownAndNoAnswer.wages.value > 0 && totalsAggregate.employment.unknownAndNoAnswer.hours.value > 0) ? totalsAggregate.employment.unknownAndNoAnswer.wages.value / totalsAggregate.employment.unknownAndNoAnswer.hours.value : 0,
                                        });
      const totalHours: number =
        totalsAggregate.employment.indigenous.hours.value +
        totalsAggregate.employment.diverse.hours.value +
        totalsAggregate.employment.diverseNo.hours.value +
        totalsAggregate.employment.unknownAndNoAnswer.hours.value;
      const totalWages: number =
        totalsAggregate.employment.indigenous.wages.value +
        totalsAggregate.employment.diverse.wages.value +
        totalsAggregate.employment.diverseNo.wages.value +
        totalsAggregate.employment.unknownAndNoAnswer.wages.value;
      const avgWagePerHour: number =
        (totalHours > 0 && totalWages > 0) ? totalWages / totalHours : 0;
      const noOfWorkers: number =
        totalsAggregate.employment.indigenous.headCount.value +
        totalsAggregate.employment.diverse.headCount.value +
        totalsAggregate.employment.diverseNo.headCount.value +
        totalsAggregate.employment.unknownAndNoAnswer.headCount.value;
      this.workforceDiversityTotals = {
        noOfWorkers,
        totalHours,
        totalWages,
        avgWagePerHour,
      };

      // Populate the Report Data Table.
      this.reportSummaryTable.parentsBold = false;
      this.reportSummaryTable.className = 'margin-bottom';
      this.reportSummaryTable.headers = this.reportHelper.workforceDiversityReportHeaders;
      this.reportSummaryTable.rows.push({ data: this.getEmploymentSummaryRow(totalsAggregate.employment.indigenous, 'Indigenous Workers'), dataValues: [], children: [] });
      this.reportSummaryTable.rows.push({ data: this.getEmploymentSummaryRow(totalsAggregate.employment.diverse, 'Equity Seeking Workers (Less Indigenous)'), dataValues: [], children: [] });
      this.reportSummaryTable.rows.push({ data: this.getEmploymentSummaryRow(totalsAggregate.employment.diverseNo, 'Non Equity Seeking Workers'), dataValues: [], children: [] });
      this.reportSummaryTable.rows.push({ data: this.getEmploymentSummaryRow(totalsAggregate.employment.unknownAndNoAnswer, 'Undeclared / Unknown Workers'), dataValues: [], children: [] });
      this.reportSummaryTable.totalRow = _.cloneDeep(EmptyReportDataTableRow);
      this.reportSummaryTable.totalRow.data = [
        formatCurrencyToString(totalWages.toString()), formatAmountToString(totalHours.toString()), formatCurrencyToString(avgWagePerHour.toString()), formatAmountToString(noOfWorkers.toString()),
      ];

      // Populate the Total Hours Report Data Table.
      this.reportOccupationByDiversityHoursTable.parentsBold = false;
      this.reportOccupationByDiversityHoursTable.className = 'margin-bottom';
      this.reportOccupationByDiversityHoursTable.headers = this.reportHelper.occupationHeadersHours;
      for (const occupation of totalsAggregate.employment.occupations) {
        this.reportOccupationByDiversityHoursTable.rows.push(this.getOccupationDiversityHoursRow(occupation));
      }
      this.reportOccupationByDiversityHoursTable.autoCalculateTotalRow = true;

      // Populate the Hourly Rate Report Data Table.
      this.reportOccupationByDiversityHourlyRateTable.parentsBold = false;
      this.reportOccupationByDiversityHourlyRateTable.className = 'margin-bottom';
      this.reportOccupationByDiversityHourlyRateTable.headers = this.reportHelper.occupationHeadersHourlyRate;
      for (const occupation of totalsAggregate.employment.occupations) {
        this.reportOccupationByDiversityHourlyRateTable.rows.push(this.getOccupationDiversityHourlyRateRow(occupation));
      }
      this.reportOccupationByDiversityHourlyRateTable.totalRow = null;
      /*
      this.reportSummaryTable.rows.push({ data: this.getEmploymentSummaryRow(totalsAggregate.employment.indigenous, 'Indigenous Workers'), dataValues: [], children: [] });
      this.reportSummaryTable.rows.push({ data: this.getEmploymentSummaryRow(totalsAggregate.employment.diverse, 'Equity Seeking Workers (Less Indigenous)'), dataValues: [], children: [] });
      this.reportSummaryTable.rows.push({ data: this.getEmploymentSummaryRow(totalsAggregate.employment.diverseNo, 'Non Equity Seeking Workers'), dataValues: [], children: [] });
      this.reportSummaryTable.rows.push({ data: this.getEmploymentSummaryRow(totalsAggregate.employment.unknownAndNoAnswer, 'Undeclared / Unknown Workers'), dataValues: [], children: [] });
      this.reportSummaryTable.totalRow = _.cloneDeep(EmptyReportDataTableRow);
      this.reportSummaryTable.totalRow.data = [
        formatCurrencyToString(totalWages.toString()), formatAmountToString(totalHours.toString()), formatCurrencyToString(avgWagePerHour.toString()), formatAmountToString(noOfWorkers.toString()),
      ];
      */

      /*
      const jobCreationValue = 2.5;
      this.jobCreationTotalsItem.description = 'DIVERSE JOB CREATION';
      this.jobCreationTotalsItem.percentageFormatted = '2.5% (345 FTE)';
      this.jobCreationTotalsItem.percentageDescription = 'of Total Employment Hours';
      this.jobCreationTotalsItem.amount = 201254;
      this.jobCreationTotalsItem.isFullWidth = true;
      this.jobCreationTotalsItem.color = colors.colorIndigenousEmployment;
      this.jobCreationTotalsItem.backgroundColor = colors.colorWhite;
      this.jobCreationTotalsItem.isCurrency = false;

      const jobCreationChartMeta: StackedBarMeta = _.cloneDeep(EmptyStackedBarMeta);
      jobCreationChartMeta.title = '';
      jobCreationChartMeta.xAxis = '';
      jobCreationChartMeta.yAxis = '';
      const jobCreationCategory = 'TOTAL EMPLOYMENT HOURS<br/><span style="font-size:34px; font-weight: bolder;">201,254</span>';
      jobCreationChartMeta.categories = [ jobCreationCategory ];
      const jobCreationSeries: any[] = [];
      const jobCreation: any[] = [ jobCreationValue ];

      jobCreationSeries.push({ name: 'Diverse Job Creation', stack: 'stack1', data: jobCreation, color: colors.colorIndigenousEmployment });

      jobCreationChartMeta.series = jobCreationSeries;
      jobCreationChartMeta.totalStacks = 1;
      jobCreationChartMeta.formatNumbersToCurrency = true;
      jobCreationChartMeta.legendEnabled = false;
      jobCreationChartMeta.xAxisVisible = false;
      jobCreationChartMeta.yAxisMax = 100;
      jobCreationChartMeta.isPercentage = true;
      jobCreationChartMeta.isCurrency = false;
      jobCreationChartMeta.height = 100;
      jobCreationChartMeta.legendColor = colors.colorIndigenousEmployment;
      jobCreationChartMeta.exportingEnabled = false;

      this.jobCreationChartData = charts.getStackedBarFromMeta(jobCreationChartMeta, null, null, 60);
      */
      this.isLoaded = true;
    }
  }

  private getEmploymentSummaryRow(aggregateValue: TotalsAggregateValueEmployment, title: string): string[] {
   return [ title,
            aggregateValue.wages.valueFormatted,
            aggregateValue.hours.valueFormatted + ' (' + aggregateValue.hours.percentageFormatted + ')',
            formatCurrencyToString(((aggregateValue.wages.value > 0 && aggregateValue.hours.value > 0) ? aggregateValue.wages.value / aggregateValue.hours.value : 0).toString()),
            aggregateValue.headCount.valueFormatted + ' (' + aggregateValue.headCount.percentageFormatted + ')',
          ];
  }

  private getOccupationDiversityHoursRow(occupationDiversityMeta: OccupationDiversityMeta): ReportDataTableRow {
   return  { data: [ occupationDiversityMeta.occupationDescription ? occupationDiversityMeta.occupationDescription : '',
            occupationDiversityMeta.indigenous.totalManHours.valueFormatted,
            occupationDiversityMeta.diverse.totalManHours.valueFormatted,
            occupationDiversityMeta.diverseNo.totalManHours.valueFormatted,
            occupationDiversityMeta.diverseUnknown.totalManHours.valueFormatted,
            occupationDiversityMeta.overall.totalManHours.value.toString(),
          ],
          children: [],
          dataValues: [
            '',
            occupationDiversityMeta.indigenous.totalManHours.value,
            occupationDiversityMeta.diverse.totalManHours.value,
            occupationDiversityMeta.diverseNo.totalManHours.value,
            occupationDiversityMeta.diverseUnknown.totalManHours.value,
            occupationDiversityMeta.overall.totalManHours.value,
          ],
    };
  }

  private getOccupationDiversityHourlyRateRow(occupationDiversityMeta: OccupationDiversityMeta): ReportDataTableRow {
   return  { data: [ occupationDiversityMeta.occupationDescription ? occupationDiversityMeta.occupationDescription : '',
            occupationDiversityMeta.indigenous.averageRate.valueFormatted,
            occupationDiversityMeta.diverse.averageRate.valueFormatted,
            occupationDiversityMeta.diverseNo.averageRate.valueFormatted,
            occupationDiversityMeta.diverseUnknown.averageRate.valueFormatted,
            occupationDiversityMeta.overall.averageRate.valueFormatted,
          ],
          children: [],
          dataValues: [],
    };
  }

}
