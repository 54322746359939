














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class LegendComponent extends Vue {
  @Prop() public bgColor: any;
  @Prop() public textColor: any;
  @Prop() public legendColor: any;
  @Prop() public legendTitle: any;
  @Prop() public totalsAggregateValue: any;

  private mounted() {
  }

  private getSize(): string {
    return '17';
  }

  private getStyle(): string {
    return 'fill:' + this.bgColor + ';stroke-width:1;stroke:' + this.bgColor;
  }
}
