




















import { Component, Vue, Prop } from 'vue-property-decorator';
import SpinnerComponent from '../components/SpinnerComponent.vue';

@Component({
  components: {
    SpinnerComponent,
  },
})
export default class MiniDonutComponent extends Vue {
  @Prop() public isLoading: any;
  @Prop() public segments: any;

  private index: any = -1;
  private processedSegments: any[] = [];

  private mounted() {
    let offset = 0;
    for (const segment of this.segments) {
      segment.offset = offset;
      this.processedSegments.push(segment);
      offset += +segment.value;
    }
  }

  private getStroke(segment: any): string {
    const val: number = +segment.value;
    return val + ' ' + (100 - val);
  }

  private getStrokeOffset(segment: any, index: number): string {
    if (index === 0) {
      return '25';
    } else {
      return ((100 - +segment.offset) + 25).toString();
    }
  }
}
