

















































import { Component, Vue, Prop } from 'vue-property-decorator';
import TooltipComponent from "./TooltipComponent.vue";
import SimpleChartComponent from './SimpleChartComponent.vue';
import MiniDonutComponent from '../components/MiniDonutComponent.vue';
import LegendComponent from '../components/LegendComponent.vue';
import { getIconUrl } from '@/lib/images';
import { convertToM, convertToPercent, convertToPercentValue } from '@/lib/currency';
import * as colors from '../lib/colors';
import * as _ from 'lodash';
import { getDonut } from '@/lib/charts';
import { DonutMeta } from '@/store/models';
import { EmptyDonutMeta } from '@/store/models-empty';

@Component({
  components: {
    TooltipComponent,
    SimpleChartComponent,
    MiniDonutComponent,
    LegendComponent,
  },
})
export default class TotalComponent extends Vue {

  @Prop() public total: any;
  @Prop() public title: any;
  @Prop() public bgColor: any;
  @Prop() public textColor: any;
  @Prop() public theme: any;
  @Prop() public isLastItem: any;
  @Prop() public tooltipText: any;
  @Prop() public showPercentage: any;
  @Prop() public percentageDescription: any;
  @Prop() public chartData: any;

  // Donut properties
  @Prop() public showDonut: any;
  @Prop() public totalsAggregate: any;
  @Prop() public showCommunityInvestment: any;
  @Prop() public showIndigenousEmployment: any;
  @Prop() public displayIndigenousProjectRevenue: any;

  private colors = colors;
  private donutChartData: any = {};
  private convertToM = convertToM;
  private convertToPercent = convertToPercent;
  private totalsUpdated: number = 0;

  private mounted() {
    if (this.showDonut) {
      this.getDonut();
    }
  }

  private getLayout(): string {
    return this.showDonut ? 'xs12 md6' : 'xs12';
  }

  private getCardStyle(): string {
    let style: string = this.getStyle();
    style += (';margin-right:' + ((this.isLastItem || this.$vuetify.breakpoint.smAndDown) ? '0px' : '30px'));
    style += (';padding-bottom:' + ((this.chartData || this.showDonut) ? '0px' : '25px'));
    return style;
  }

  private getStyle(): string {
    let style = ('background-color: ' + (this.bgColor ? this.bgColor : '#18374B'));
    style += (';color:' + (this.textColor ? this.textColor : '#FFFFFF'));
    return style;
  }

  private getTooltipIcon(): string {
    return getIconUrl(this.theme === 'light' ? 'icon_info_dark.png' : 'icon_info.png');
  }

  private getDonut() {
    const donutMeta: DonutMeta = _.cloneDeep(EmptyDonutMeta);
    donutMeta.bgColor = 'transparent';
    donutMeta.legendEnabled = false;
    donutMeta.formatNumbersToCurrency = false;
    const series: any[] = [
      { name: 'Tangible Net Benefits',
        innerSize: '50%',
        data: [
          { name: 'Project Benefits', dollarAmount: this.totalsAggregate.tnb.projectBenefits.indigenous.valueFormatted, y: convertToPercentValue(this.totalsAggregate.tnb.projectBenefits.indigenous.value, this.totalsAggregate.tnb.overall.indigenous.value), color: colors.colorProjectBenefits },
        ],
      },
    ];
    if (this.showIndigenousEmployment) {
      series[0].data.push({ name: 'Indigenous Employment', dollarAmount: this.totalsAggregate.tnb.employment.indigenous.valueFormatted, y: convertToPercentValue(this.totalsAggregate.tnb.employment.indigenous.value, this.totalsAggregate.tnb.overall.indigenous.value), color: colors.colorEmployment });
    }
    if (this.showCommunityInvestment) {
      series[0].data.push({ name: 'Community Investment', dollarAmount: this.totalsAggregate.tnb.communityContributions.indigenous.valueFormatted, y: convertToPercentValue(this.totalsAggregate.tnb.communityContributions.indigenous.value, this.totalsAggregate.tnb.overall.indigenous.value), color: colors.colorCommunityInvestment });
    }
    this.donutChartData = this.donutChartData = getDonut(series, donutMeta);
    this.totalsUpdated++;
  }
}
