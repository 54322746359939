import { Aggregate, ResourceSearchResult, FacetDetailMeta, TotalsMeta, CommonMeta, Project, StackedBarMeta, TreeMapMeta, LineChartMeta, BulletGraphMeta, SummaryTotalsMeta, ExpenseTotalsMeta, ExpensesTotalsMeta, RevenueTotalsMeta, EmploymentTotalsMeta, VendorMeta, Vendor, ExpenseEmploymentTotalsMeta, DiversityTotalsType, DiversityTotalsMeta, DiversityReferenceData, DiversityType, DiversityRefDataCategory, DonutMeta } from '../store/models';
import * as colors from '../lib/colors';
import { chart } from 'highcharts';
import { getDISTotal, getTNBTotal } from './aggregate';
import { getDiversityClassNameFromCode, getDiversityCorrespondingCategory, getDiversityNameFromCode } from './shared';

export const pointWidth: number = 35;
export const pointWidthSmall: number = 12;

/*
export function getBarChartCategories(aggregateResult: ResourceSearchResult|null, maxChartItems: number, includeCommunityInvestments: boolean = false, includeProjectRevenue: boolean = false, includeDIS: boolean = false): string[] {
  const categories: string[] = [];
  let chartItemCount: number = 0;
  for (const result of (aggregateResult as ResourceSearchResult).searchResults.results) {
    if (chartItemCount < maxChartItems) {
      const totals: SummaryTotalsMeta = (result as any).totals as SummaryTotalsMeta;
      if ((includeDIS && totals.expense.indigenousSpend > 0)
      || totals.contributions.totalProjectContributions > 0
      || (includeCommunityInvestments && totals.contributions.totalCommunityContributions > 0)
      || (includeProjectRevenue && (totals.revenue.indigenousProjectRevenue > 0))
      || totals.employment.indigenousEmploymentValue > 0) {
        categories.push(((result as Aggregate).facetDetail as FacetDetailMeta).displayName as string);
        chartItemCount++;
      }
    }
  }
  return categories;
}

export function getBarChartCategoriesNonAggregate(aggregateResult: ResourceSearchResult|null, maxChartItems: number, includeCommunityInvestments: boolean = false, includeProjectRevenue: boolean = false, includeDIS: boolean = false): string[] {
  const categories: string[] = [];
  let chartItemCount: number = 0;
  for (const result of (aggregateResult as ResourceSearchResult).searchResults.results) {
    if (chartItemCount < maxChartItems) {
      const totals: TotalsMeta = (result as any).totals as TotalsMeta;
      const totalIndigenousSpend: number = (totals.expenses as ExpensesTotalsMeta) ? (totals.expenses as ExpensesTotalsMeta).socialProcurement.totalIndigenousSpend : 0;
      const totalProjectRevenue: number = (totals.revenue as RevenueTotalsMeta) ? (totals.revenue as RevenueTotalsMeta).revenueTotals.totalProjectRevenue : 0;
      const totalProjectContributions: number = (totals.expenses as ExpensesTotalsMeta) ? (totals.expenses as ExpensesTotalsMeta).expenseTotals.totalIndigenousProjectContributions : 0;
      const totalCommunityContributions: number = (totals.expenses as ExpensesTotalsMeta) ? (totals.expenses as ExpensesTotalsMeta).expenseTotals.totalCommunityContributions : 0;
      const totalEmployment: number = (totals.employment as EmploymentTotalsMeta) ? (totals.employment as EmploymentTotalsMeta).indigenous.totalWages : 0;
      if ((includeDIS && totalIndigenousSpend > 0)
      || totalProjectContributions > 0
      || (includeCommunityInvestments && totalCommunityContributions > 0)
      || (includeProjectRevenue && totalProjectRevenue > 0)
      || totalEmployment > 0) {
        categories.push(((result as any).common as CommonMeta).displayName as string);
        chartItemCount++;
      }
    }
  }
  return categories;
}

export function getBarChartSeriesOverallNonAggregate(aggregateResult: ResourceSearchResult|null, maxChartItems: number): any {
  const series: any[] = [];
  const totalOverall: any = [];
  let chartItemCount: number = 0;
  for (const result of (aggregateResult as ResourceSearchResult).searchResults.results) {
    if (chartItemCount < maxChartItems) {
      const totals: TotalsMeta = (result as any).totals as TotalsMeta;
      const totalProjectContributions: number = (totals.expenses as ExpensesTotalsMeta) ? (totals.expenses as ExpensesTotalsMeta).expenseTotals.totalIndigenousProjectContributions : 0;
      const totalCommunityContributions: number = (totals.expenses as ExpensesTotalsMeta) ? (totals.expenses as ExpensesTotalsMeta).expenseTotals.totalCommunityContributions : 0;
      const totalEmployment: number = (totals.employment as EmploymentTotalsMeta) ? (totals.employment as EmploymentTotalsMeta).indigenous.totalWages : 0;
      if (totalProjectContributions > 0
      || totalCommunityContributions > 0
      || totalEmployment > 0) {
        totalOverall.push(getSeriesPoint((result as Aggregate), false, totalProjectContributions + totalCommunityContributions + totalEmployment));
        chartItemCount++;
      }
    }
  }
  series.push({ name: 'Total Contributions', stack: 'stack1', data: totalOverall});
  return series;
}

export function getBarChartCategoriesProject(aggregateResult: ResourceSearchResult|null, maxChartItems: number): string[] {
  const categories: string[] = [];
  let chartItemCount: number = 0;
  for (const result of (aggregateResult as ResourceSearchResult).searchResults.results) {
    if (chartItemCount < maxChartItems) {
      const totals: SummaryTotalsMeta = (result as any).totals as SummaryTotalsMeta;
      if (totals.expense.indigenousSpend > 0
      || totals.revenue.indigenousProjectRevenue > 0
      || totals.contributions.totalProjectContributions > 0
      || totals.employment.indigenousEmploymentValue > 0
      || totals.contributions.totalCommunityContributions > 0) {
        const category = (result as Aggregate).facetDetail
                          ? ((result as Aggregate).facetDetail as FacetDetailMeta).displayName as string
                          : ((result as Project).common as CommonMeta).displayName as string;
        categories.push(category);
        chartItemCount++;
      }
    }
  }
  return categories;
}

export function getBarChartSeriesProject(aggregateResult: ResourceSearchResult|null, maxChartItems: number, canDrillDown: boolean = false, categories: string[] = [], chartColors: string[] = []): any {
  const data: any[] = [];
  const totalProjectRevenue: number[] = [];
  const directIndigenousSpend: number[] = [];
  const tnbProjectBenefits: number[] = [];
  const tnbCommunityInvestments: number[] = [];
  const tnbIndigenousEmployments: number[] = [];
  let chartItemCount: number = 0;
  for (const result of (aggregateResult as ResourceSearchResult).searchResults.results) {
    if (chartItemCount < maxChartItems) {
      const totals: SummaryTotalsMeta = (result as any).totals as SummaryTotalsMeta;
      if (totals.expense.indigenousSpend > 0
      || totals.revenue.indigenousProjectRevenue > 0
      || totals.contributions.totalProjectContributions > 0
      || totals.employment.indigenousEmploymentValue > 0
      || totals.contributions.totalCommunityContributions > 0) {
        directIndigenousSpend.push(getSeriesPoint((result as Aggregate), canDrillDown, totals.expense.indigenousSpend));
        totalProjectRevenue.push(getSeriesPoint((result as Aggregate), canDrillDown, totals.revenue.indigenousProjectRevenue));
        tnbProjectBenefits.push(getSeriesPoint((result as Aggregate), canDrillDown, totals.contributions.totalProjectContributions));
        tnbCommunityInvestments.push(getSeriesPoint((result as Aggregate), canDrillDown, totals.contributions.totalCommunityContributions));
        tnbIndigenousEmployments.push(getSeriesPoint((result as Aggregate), canDrillDown, totals.employment.indigenousEmploymentValue));
        chartItemCount++;
      }
    }
  }
  data.push({ name: 'Total Project Revenue', stack: 'stack1', data: totalProjectRevenue, categories, color: chartColors[0]});
  data.push({ name: 'Direct Indigenous Spend', stack: 'stack2', data: directIndigenousSpend, categories, color: chartColors[1]});
  data.push({ name: 'Tangible Net Benefits: Project Benefits', stack: 'stack3', data: tnbProjectBenefits, categories, color: chartColors[2]});
  data.push({ name: 'Tangible Net Benefits: Indigenous Employment', stack: 'stack3', data: tnbIndigenousEmployments, categories, color: chartColors[3]});
  return data;
}
*/

export function getLineChartSeriesPoint(name: string, data: number[], pointStart: number, pointInterval: number) {
  return {
    name,
    data,
    pointStart,
    pointInterval,
  };
}

export function getSeriesPoint(result: Aggregate|Project|string, canDrillDown: boolean, amount: number): any {
  return { y: amount, drilldown: canDrillDown, identifier:
            (result as Aggregate).facetDetail ?
              (result as Aggregate).facetDetail.identifier :
              ((result as Project).common as CommonMeta) ? ((result as Project).common as CommonMeta).displayName as string
                : result };
}

export function getSortedResults(results: any[]): any[] {
  const sortedResults: any[] = results.sort((n1, n2) => {
    if (n1.total < n2.total) {
        return 1;
    } else if (n1.total > n2.total) {
        return -1;
    } else {
      return 0;
    }
  });
  return sortedResults;
}

export function getDiversityTotal(diversityType: DiversityType): number {
  let total: number = 0;
  for (const payee of diversityType.payees) {
    total += (payee.equipment + payee.goods + payee.services);
  }
  return total;
}

export function getSortedDiverseSupplierResults(aggregateResult: ResourceSearchResult|null, diversityReferenceData: DiversityReferenceData|null): any[] {
  const results: any[] = [];
  const totalsMeta: DiversityTotalsMeta = ((aggregateResult as ResourceSearchResult).searchResults.results as Aggregate[])[0].totals as DiversityTotalsMeta;
  const impactEsg: DiversityTotalsType|undefined = totalsMeta.esgByClass !== undefined ? totalsMeta.esgByClass.find((x) => x.diversityClass === 'impactESG') : undefined;
  const socialFocusedOrgs: DiversityTotalsType|undefined = totalsMeta.esgByClass !== undefined ? totalsMeta.esgByClass.find((x) => x.diversityClass === 'socialFocusedOrgs') : undefined;
  if (impactEsg !== undefined) {
    for (const diversityType of impactEsg.diversityTypes) {
      // Get the Certified total.
      const certifiedTotal: number = getDiversityTotal(diversityType);
      let selfDeclaredTotal: number = 0;
      let total: number = certifiedTotal;
      const category: string = diversityType.diversityType;
      const name: string = diversityReferenceData ? getDiversityClassNameFromCode(diversityReferenceData, category, 'impactESG') : category;
      const correspondingCategory: DiversityRefDataCategory|undefined = diversityReferenceData ? getDiversityCorrespondingCategory(diversityReferenceData, category, 'impactESG', 'socialFocusedOrgs') : undefined;
      // Get the Self-declared total.
      if (socialFocusedOrgs !== undefined && correspondingCategory !== undefined) {
        const correspondingDiversityType: DiversityType|undefined = socialFocusedOrgs.diversityTypes.find((x) => x.diversityType === correspondingCategory.code);
        if (correspondingDiversityType !== undefined) {
          // Add to the existing total.
          selfDeclaredTotal = getDiversityTotal(correspondingDiversityType);
          total += selfDeclaredTotal;
        }
      }
      if (total > 0 && name !== '') {
        results.push({category, name, total, certifiedTotal, selfDeclaredTotal});
      }
    }
  }
  if (socialFocusedOrgs !== undefined) {
    for (const diversityType of socialFocusedOrgs.diversityTypes) {
      // Get the Self-declared total.
      const certifiedTotal: number = 0;
      const selfDeclaredTotal: number = getDiversityTotal(diversityType);
      const total: number = selfDeclaredTotal;
      const category: string = diversityType.diversityType;
      const name: string = diversityReferenceData ? getDiversityClassNameFromCode(diversityReferenceData, category, 'socialFocusedOrgs') : category;
      if (total > 0 && name !== '') {
        // If we haven't already added it then add it.
        if (results.find((x) => x.name === name) === undefined) {
          results.push({category, name, total, certifiedTotal, selfDeclaredTotal});
        }
      }
    }
  }
  return getSortedResults(results);
}

export function getSortedTNBResults(aggregateResult: ResourceSearchResult|null, isAggregate: boolean = true, includeCommunityContributions: boolean = true, includeIndigenousEmployment: boolean = true, includeProjectRevenue: boolean = false, includeDIS: boolean = false): any[] {
  const results: any[] = [];
  for (const result of (aggregateResult as ResourceSearchResult).searchResults.results) {
    let total: number = 0;
    let directIndigenousSpend: number = 0;
    //TODO: Project Revenue if we need that anywhere.
    const projectRevenue: number = 0;
    let category: string = '';
    let identifier: string = '';
    const totals: TotalsMeta | SummaryTotalsMeta = (result as any).totals;
    total = getTNBTotal(totals, includeCommunityContributions, includeIndigenousEmployment);
    directIndigenousSpend = includeDIS ? getDISTotal(totals) : 0;
    if (isAggregate) {
      category = ((result as Aggregate).facetDetail as FacetDetailMeta).displayName as string;
      identifier = ((result as Aggregate).facetDetail as FacetDetailMeta).identifier as string;
    } else {
      category = ((result as any).common as CommonMeta).displayName as string;
      identifier = ((result as any).common as CommonMeta).identifier as string;
    }
    results.push({ category, total, directIndigenousSpend, projectRevenue, identifier });
  }
  return getSortedResults(results);
}

export function getTnbBarChartSeries(searchResults: ResourceSearchResult|null, maxChartItems: number, label: string, isAggregate: boolean = true, isOverallTotal: boolean = true, includeCommunityContributions: boolean = true, includeIndigenousEmployment: boolean = true, includeProjectRevenue: boolean = false, includeDIS: boolean = false, canDrillDown: boolean = false): any {
  const sortedResults = getSortedTNBResults(searchResults, isAggregate, includeCommunityContributions, includeIndigenousEmployment, includeProjectRevenue, includeDIS);
  const series: any[] = [];
  const tnbTotal: number[] = [];
  const totalProjectRevenue: any = [];
  const directIndigenousSpend: any = [];
  const tnbProjectBenefits: number[] = [];
  const tnbCommunityContributions: number[] = [];
  const tnbIndigenousEmployments: number[] = [];
  let chartItemCount: number = 0;
  for (const result of sortedResults) {
    if ((result.total > 0 || (includeDIS && result.directIndigenousSpend > 0) || (includeProjectRevenue && result.projectRevenue > 0))  && chartItemCount < maxChartItems) {
      if (isOverallTotal) {
        tnbTotal.push(getSeriesPoint(canDrillDown ? (result.identifier as string) : (result.category as string), canDrillDown, result.total));
      } else {
        // Get the result from the search results.
        const searchResult =
          isAggregate ? ((searchResults as ResourceSearchResult).searchResults.results as Aggregate[]).find((x: Aggregate) => (x.facetDetail as FacetDetailMeta).identifier === result.identifier)
                      : ((searchResults as ResourceSearchResult).searchResults.results as any[]).find((x: any) => (x.common as CommonMeta).identifier === result.identifier);
        if (searchResult) {
          // Add the various totals.
          tnbProjectBenefits.push(getSeriesPoint((result as Aggregate), canDrillDown, (searchResult.totals as TotalsMeta).expenses ? ((searchResult.totals as TotalsMeta).expenses as ExpensesTotalsMeta).expenseTotals.totalIndigenousProjectContributions : 0));
          if (includeIndigenousEmployment) {
            tnbIndigenousEmployments.push(getSeriesPoint((result as Aggregate), canDrillDown, ((searchResult.totals as TotalsMeta).expenses ? (((searchResult.totals as TotalsMeta).expenses as ExpensesTotalsMeta).employment as ExpenseEmploymentTotalsMeta).totalIndigenousEmploymentValue : 0)));
          }
          if (includeCommunityContributions) {
            tnbCommunityContributions.push(getSeriesPoint((result as Aggregate), canDrillDown, (searchResult.totals as TotalsMeta).expenses ? ((searchResult.totals as TotalsMeta).expenses as ExpensesTotalsMeta).expenseTotals.totalIndigenousCommunityContributions : 0));
          }
          if (includeDIS) {
            directIndigenousSpend.push(getSeriesPoint((result as Aggregate), canDrillDown, (searchResult.totals as TotalsMeta).expenses ? ((searchResult.totals as TotalsMeta).expenses as ExpensesTotalsMeta).diverseSpend.totalIndigenousSpend : 0));
          }
          if (includeProjectRevenue) {
            totalProjectRevenue.push(getSeriesPoint((result as Aggregate), canDrillDown, (searchResult.totals as TotalsMeta).revenue ? ((searchResult.totals as TotalsMeta).revenue as RevenueTotalsMeta).revenueTotals ? ((searchResult.totals as TotalsMeta).revenue as RevenueTotalsMeta).revenueTotals.totalProjectRevenue : 0 : 0));
          }
        }
      }
      chartItemCount++;
    }
  }
  if (isOverallTotal) {
    series.push({ name: label, stack: 'stack1', data: tnbTotal});
  } else {
    if (includeProjectRevenue) {
      series.push({ name: 'Total Project Revenue', stack: 'stack1', data: totalProjectRevenue});
    }
    if (includeDIS) {
      series.push({ name: 'Direct Indigenous Spend', stack: 'stack2', data: directIndigenousSpend});
    }
    series.push({ name: 'Tangible Net Benefits: Project Benefits', index: 2, legendIndex: 0, stack: 'stack3', data: tnbProjectBenefits});
    if (includeCommunityContributions) {
      series.push({ name: 'Tangible Net Benefits: Community Contribution', index: 1, legendIndex: 1, stack: 'stack3', data: tnbCommunityContributions});
    }
    if (includeIndigenousEmployment) {
      series.push({ name: 'Tangible Net Benefits: Indigenous Employment', index: 0, legendIndex: 3, stack: 'stack3', data: tnbIndigenousEmployments});
    }
  }
  return series;
}

export function getTnbBarChartCategories(aggregateResult: ResourceSearchResult|null, maxChartItems: number, isAggregate: boolean = true, includeCommunityContributions: boolean = true, includeIndigenousEmployment: boolean = true, includeProjectRevenue: boolean = false, includeDIS: boolean = false): string[] {
  const categories: string[] = [];
  const sortedResults = getSortedTNBResults(aggregateResult, isAggregate, includeCommunityContributions, includeIndigenousEmployment, includeProjectRevenue, includeDIS);
  let chartItemCount: number = 0;
  for (const result of sortedResults) {
    if ((result.total > 0 || (includeDIS && result.directIndigenousSpend > 0) || (includeProjectRevenue && result.projectRevenue > 0)) && chartItemCount < maxChartItems) {
      categories.push(result.category);
      chartItemCount++;
    }
  }
  return categories;
}

export function getDiverseSupplierCategories(aggregateResult: ResourceSearchResult|null, diversityReferenceData: DiversityReferenceData|null): string[] {
  const categories: string[] = [];
  const sortedResults = getSortedDiverseSupplierResults(aggregateResult, diversityReferenceData);
  let chartItemCount: number = 0;
  for (const result of sortedResults) {
    if (result.total > 0) {
      categories.push(result.name);
      chartItemCount++;
    }
  }
  return categories;
}

export function getDiverseSupplierSeries(searchResults: ResourceSearchResult|null, diversityReferenceData: DiversityReferenceData|null): any {
  const sortedResults = getSortedDiverseSupplierResults(searchResults, diversityReferenceData);
  const series: any[] = [];
  const certifiedTotal: number[] = [];
  const selfDeclaredTotal: number[] = [];
  let chartItemCount: number = 0;
  for (const result of sortedResults) {
    if (result.total > 0) {
      certifiedTotal.push(getSeriesPoint((result.category as string), false, result.certifiedTotal));
      selfDeclaredTotal.push(getSeriesPoint((result.category as string), false, result.selfDeclaredTotal));
      chartItemCount++;
    }
  }
  series.push({ name: 'Third Party Certified', stack: 'stack1', data: certifiedTotal});
  series.push({ name: 'Self Declared', stack: 'stack1', data: selfDeclaredTotal});
  return series;
}


/*
export function getBarChartSeriesOverall(aggregateResult: ResourceSearchResult|null, maxChartItems: number): any {
  const series: any[] = [];
  const totalOverall: any = [];
  let chartItemCount: number = 0;
  for (const result of (aggregateResult as ResourceSearchResult).searchResults.results) {
    if (chartItemCount < maxChartItems) {
      const totals: SummaryTotalsMeta = (result as any).totals as SummaryTotalsMeta;
      if (totals.contributions.totalProjectContributions > 0
      || totals.contributions.totalCommunityContributions > 0
      || totals.employment.indigenousEmploymentValue > 0) {
        totalOverall.push(getSeriesPoint((result as Aggregate), false, totals.contributions.totalProjectContributions + totals.contributions.totalCommunityContributions + totals.employment.indigenousEmploymentValue));
        chartItemCount++;
      }
    }
  }
  series.push({ name: 'Total Contributions', stack: 'stack1', data: totalOverall});
  return series;
}

export function getBarChartCategoriesOverall(aggregateResult: ResourceSearchResult|null, maxChartItems: number): string[] {
  const categories: string[] = [];
  let chartItemCount: number = 0;
  for (const result of (aggregateResult as ResourceSearchResult).searchResults.results) {
    if (chartItemCount < maxChartItems) {
      const totals: SummaryTotalsMeta = (result as any).totals as SummaryTotalsMeta;
      if (totals.contributions.totalProjectContributions > 0
        || totals.contributions.totalCommunityContributions > 0
        || totals.employment.indigenousEmploymentValue > 0) {
          categories.push(((result as Aggregate).facetDetail as FacetDetailMeta).displayName as string);
          chartItemCount++;
      }
    }
  }
  return categories;
}
*/

export function getBarChartSeries(aggregateResult: ResourceSearchResult|null, maxChartItems: number, includeCommunityInvestments: boolean = false, includeProjectRevenue: boolean = false, includeDIS: boolean = false, categories: string[] = [], canDrillDown: boolean = false): any {
  const series: any[] = [];
  const totalProjectRevenue: any = [];
  const directIndigenousSpend: any = [];
  const tnbProjectBenefits: number[] = [];
  const tnbCommunityInvestments: number[] = [];
  const tnbIndigenousEmployments: number[] = [];
  let chartItemCount: number = 0;
  // for (const result of (aggregateResult as ResourceSearchResult).searchResults.results) {
  for (const category of categories) {
    const result: Aggregate|undefined = ((aggregateResult as ResourceSearchResult).searchResults.results as Aggregate[]).find((x) => x.facetDetail.displayName === category);
    if (result && chartItemCount < maxChartItems) {
      const totals: SummaryTotalsMeta = (result as any).totals as SummaryTotalsMeta;
      if ((includeDIS && totals.expense.indigenousSpend > 0)
      || totals.contributions.totalProjectContributions > 0
      || (includeCommunityInvestments && totals.contributions.totalCommunityContributions > 0)
      || (includeProjectRevenue && totals.revenue.indigenousProjectRevenue > 0)
      || totals.employment.indigenousEmploymentValue > 0) {
        if (includeProjectRevenue) {
          totalProjectRevenue.push(getSeriesPoint((result as Aggregate), canDrillDown, totals.revenue.indigenousProjectRevenue));
        }
        if (includeCommunityInvestments) {
          tnbCommunityInvestments.push(getSeriesPoint((result as Aggregate), canDrillDown, totals.contributions.totalCommunityContributions));
        }
        if (includeDIS) {
          directIndigenousSpend.push(getSeriesPoint((result as Aggregate), canDrillDown, totals.expense.indigenousSpend));
        }
        tnbProjectBenefits.push(getSeriesPoint((result as Aggregate), canDrillDown, totals.contributions.totalProjectContributions));
        tnbIndigenousEmployments.push(getSeriesPoint((result as Aggregate), canDrillDown, totals.employment.indigenousEmploymentValue));
        chartItemCount++;
      }
    }
  }
  if (includeProjectRevenue) {
    series.push({ name: 'Total Project Revenue', stack: 'stack1', data: totalProjectRevenue});
  }
  if (includeDIS) {
    series.push({ name: 'Direct Indigenous Spend', stack: 'stack2', data: directIndigenousSpend});
  }
  series.push({ name: 'Tangible Net Benefits: Project Benefits', stack: 'stack3', data: tnbProjectBenefits});
  if (includeCommunityInvestments) {
    series.push({ name: 'Tangible Net Benefits: Community Contribution', stack: 'stack3', data: tnbCommunityInvestments});
  }
  series.push({ name: 'Tangible Net Benefits: Indigenous Employment', stack: 'stack3', data: tnbIndigenousEmployments});
  return series;
}

export function getReportsStackedBarHeight(categories: string[], totalStacks: number, offset: number, barWidth = pointWidthSmall) {
  const p: number = (barWidth * totalStacks); // height of each category
  const y: number = (categories.length * p); // total height of chart minus labels etc
  const chartHeight: number = y + offset;
  return chartHeight;
}

export function getStackedBarHeight(filtersHidden: boolean, categories: string[], series: any, totalStacks: number) {
  const t: number = 54; // title
  const x: number = 61 * (filtersHidden ? 1 : 1.5); // x legend
  // const l: number = (20 * Math.ceil(series.length / 2)); // x labels -not sure what these are so removed.
  const m: number = 26; // bottom margin
  const p: number = (pointWidth * totalStacks) + 2; // height of each category
  const y: number = (categories.length * p); // total height of chart minus labels etc
  // const chartHeight: number = t + x + l + y + m;
  const chartHeight: number = t + x + y + m;
  return chartHeight;
}

export function getTreeMapFromMeta(chartMeta: TreeMapMeta, setRootNode: any = {}) {
  const treeMapData: any = {
      chart: {
        style: getChartStyle(),
        animation: false,
      },
      plotOptions: {
        series: {
            animation: false,
        },
        treemap: {
          allowTraversingTree: chartMeta.allowTraversingTree,
        },
      },
      exporting: {
        enabled: true,
        sourceWidth: 1400,
        allowHTML: true,
        useMultiLevelHeaders: false,
        chartOptions: getExportingChartOptions(),
    },
    credits: {
      enabled: false,
    },
    series: [{
      type: 'treemap',
      alternateStartingDirection: true,
      levelIsConstant: false,
      levels: [{
        level: 1,
        layoutAlgorithm: 'sliceAndDice',
        dataLabels: {
            enabled: true,
            align: 'center',
            verticalAlign: 'middle',
            style: {
                fontSize: '20px',
                font: 'Roboto-Regular',
                textOutline: false,
                fontWeight: 'bold',
            },
        },
      }],
      dataLabels: {
        enabled: false,
      },
      data: chartMeta.data,
      events: {
        setRootNode,
      },
    }],
    title: {
      text: chartMeta.title,
      style: getChartTitleStyle(),
      align: 'left',
      useHTML: true,
    },
  };
  return treeMapData;
}

export function getStackedBarFromMeta(chartMeta: StackedBarMeta, drilldown: any = [], drillup: any = [], barWidth: number = pointWidth) {
  const chartHeight: number = chartMeta.height > 0 ? chartMeta.height : getStackedBarHeight(true, chartMeta.categories, chartMeta.series, chartMeta.totalStacks);
  const barData: any = {
    chart: {
      type: 'bar',
      height: chartHeight,
      style: getChartStyle(chartMeta.theme),
      animation: false,
      backgroundColor: chartMeta.bgColor ? chartMeta.bgColor : '#FFFFFF',
    },
    title: {
        text: chartMeta.title,
        style: getChartTitleStyle(chartMeta.theme),
        align: 'left',
    },
    legend: getChartLegend(chartMeta),
    responsive: getResponsive(chartMeta, chartHeight),
    credits: {
      enabled: false,
    },
    xAxis: {
        visible: chartMeta.xAxisVisible,
        categories: chartMeta.categories,
        title: {
          text: chartMeta.xAxis,
          style: getChartLegendStyle(chartMeta.theme, chartMeta.legendColor),
        },
        labels: {
          style: getChartCategoriesStyle(chartMeta.theme, chartMeta.legendColor),
        },
    },
    yAxis: {
        visible: chartMeta.yAxisVisible,
        allowDecimals: false,
        min: 0,
        max: chartMeta.yAxisMax,
        title: {
          text: chartMeta.yAxis,
          style: getChartLegendStyle(chartMeta.theme, chartMeta.legendColor),
        },
        labels: {
          style: getChartLegendStyle(chartMeta.theme, chartMeta.legendColor),
        },
    },
    colors: chartMeta.colors.length > 0 ? chartMeta.colors : colors.colors,
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b><br/>',
        valuePrefix: chartMeta.isCurrency ? '$' : '',
        valueSuffix: chartMeta.isPercentage ? '%' : '',
        shared: false,
    },
    plotOptions: {
        series: {
          stacking: 'normal',
          pointWidth: barWidth,
          animation: false,
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              enabled: false,
            },
          },
        },
        bar: {
          groupPadding: 0,
        },
    },
    series: chartMeta.series,
    exporting: {
      enabled: chartMeta.exportingEnabled,
      sourceWidth: 1400,
      allowHTML: true,
      sourceHeight: chartHeight,
      useMultiLevelHeaders: false,
      showTable: chartMeta.showTable,
      chartOptions: getExportingChartOptions(),
   },
   formatNumbersToCurrency: chartMeta.formatNumbersToCurrency,
  };

  if (drilldown || drillup) {
    barData.chart.events = {
      drilldown,
      drillup,
    };
  }
  return barData;
}

export function getResponsive(chartMeta: any, chartHeight: number) {
  return {
    rules: [
      {
        condition: {
          minWidth: 331,
        },
        chartOptions: getResponsiveChartOptions(chartMeta.legendRightAlign ? (chartHeight + 150) : chartHeight),
      },
      {
        condition: {
          maxWidth: 330,
        },
        chartOptions: getResponsiveChartOptions(chartMeta.legendRightAlign ? chartHeight + 300 : (chartHeight + 50)),
      },
    ],
  };
}

export function getDonutChartLegend(chartMeta: any) {
  return chartMeta.legendEnabled ? {
    itemStyle: getChartLegendStyle(chartMeta.theme, chartMeta.legendColor, chartMeta.legendRightAlign),
    itemHoverStyle: null,
    itemHiddenStyle: null,
    symbolRadius: 0,
    align: 'left',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    width: null,
    itemWidth: null,
    itemMarginTop: 5,
    reversed: true,
  } : {
    enabled: false,
  };
}

export function getChartLegend(chartMeta: any) {
  return chartMeta.legendEnabled ? {
    itemStyle: getChartLegendStyle(chartMeta.theme, chartMeta.legendColor, chartMeta.legendRightAlign),
    itemHoverStyle: null,
    itemHiddenStyle: null,
    symbolRadius: 0,
    align: chartMeta.legendRightAlign ? 'right' : 'left',
    verticalAlign: (chartMeta.legendRightAlign || chartMeta.legendLeftAlign) ? 'middle' : 'bottom',
    layout: (chartMeta.legendRightAlign || chartMeta.legendLeftAlign) ? 'vertical' : 'horizontal',
    // width: (chartMeta.legendRightAlign || chartMeta.legendLeftAlign) ? 400 : null,
    itemWidth: (chartMeta.legendRightAlign || chartMeta.legendLeftAlign) ? 370 : null,
    itemMarginTop: 5,
    reversed: true,
  } : {
    enabled: false,
  };
}

export function getResponsiveChartOptions(chartHeight: number) {
  return {
    chart: {
      height: chartHeight,
    },
    legend: {
      width: null,
      itemWidth: null,
      itemStyle: {
        width: null,
      },
      align: 'left',
      verticalAlign: 'bottom',
      layout: 'horizontal',
    },
  };
}

export function getTimeSeriesBarFromMeta(chartMeta: StackedBarMeta) {
  const chartHeight: number = chartMeta.height > 0 ? chartMeta.height : getStackedBarHeight(true, chartMeta.categories, chartMeta.series, chartMeta.totalStacks);
  const barData: any = {
    chart: {
      type: 'bar',
      height: chartHeight,
      style: getChartStyle(),
      defaultSeriesType: 'column',
    },
    title: {
        text: chartMeta.title,
        style: getChartTitleStyle(),
        align: 'left',
    },
    legend: {
      enabled: false,
    },
    /*
    legend: {
      itemStyle: getChartLegendStyle(),
      align: 'left',
    },
    */
    credits: {
      enabled: false,
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%e-%b-%Y',
          week: '%e-%b-%Y',
          month: '%b-%Y',
          year: '%b-%Y',
        },
        /* categories: chartMeta.categories, */
        title: {
          text: chartMeta.xAxis,
          style: getChartLegendStyle(),
        },
        labels: {
          style: getChartLegendStyle(),
        },
    },
    yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: chartMeta.yAxis,
          style: getChartLegendStyle(),
        },
        labels: {
          style: getChartLegendStyle(),
        },
    },
    colors: chartMeta.colors.length > 0 ? chartMeta.colors : colors.colors,
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b><br/>',
        valuePrefix: '$',
        shared: true,
    },
    plotOptions: {
        series: {
          stacking: 'normal',
          pointWidth,
        },
        bar: {
          groupPadding: 0,
        },
    },
    series: chartMeta.series,
    exporting: {
      enabled: true,
      sourceWidth: 1400,
      allowHTML: true,
      sourceHeight: chartHeight,
      useMultiLevelHeaders: false,
      chartOptions: getExportingChartOptions(),
   },
   formatNumbersToCurrency: chartMeta.formatNumbersToCurrency,
  };
  return barData;
}

export function getChartTitleColor(theme: string = 'light') {
  switch (theme) {
    case 'light':
      return colors.colorBlack;
    case 'dark':
      return colors.colorWhite;
    case 'social':
      return colors.colorWhite;
    default:
     return '';
  }
}

export function getChartBackgroundColor(theme: string = 'light') {
  switch (theme) {
    case 'light':
      return colors.colorWhite;
    case 'dark':
      return colors.colorDarkBlue;
    case 'social':
      return colors.colorSocial;
    default:
     return '';
  }
}

export function getChartLegendColor(theme: string = 'light') {
  switch (theme) {
    case 'light':
      return colors.colorDarkBlue;
    case 'dark':
      return colors.colorWhite;
    case 'social':
      return colors.colorWhite;
    default:
     return '';
  }
}

export function getChartStyle(theme: string = 'light') {
  return {
    fontFamily: 'Roboto',
  };
}

export function getChartTitleStyle(theme: string = 'light') {
  return {
    color: getChartTitleColor(theme),
    fontFamily: 'Roboto',
    fontSize: '22px',
  };
}

export function getChartSubTitleStyle(theme: string = 'light') {
  return {
    color: getChartTitleColor(theme),
    fontFamily: 'Roboto',
    fontSize: '18px',
  };
}

export function getChartCategoriesStyle(theme: string = 'light', color: string = '') {
  return {
    fontFamily: 'Roboto',
    color: color !== '' ? color : getChartLegendColor(theme),
    fontSize: '20px',
  };
}

export function getChartLegendStyle(theme: string = 'light', color: string = '', legendRightAlign: boolean = false) {
  return {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: color !== '' ? color : getChartLegendColor(theme),
    fontSize: '16px',
    width: legendRightAlign ? 370 : null,
    textOverflow: null,
  };
}

export function getExportingChartOptions() {
  return {
    chart: {
      style: {
        fontFamily: 'Arial',
      },
    },
    title: {
      style: {
        fontFamily: 'Arial',
      },
    },
    legend: {
      style: {
        fontFamily: 'Arial',
      },
    },
    labels: {
      style: {
        fontFamily: 'Arial',
      },
    },
  };
}

export function getBulletGraphFromMeta(chartMeta: BulletGraphMeta) {
  const bulletGraphData: any = {
    chart: {
      type: 'bullet',
      height: chartMeta.height,
      inverted: true,
      style: getChartStyle(chartMeta.theme),
      backgroundColor: getChartBackgroundColor(chartMeta.theme),
      animation: false,
      marginLeft: chartMeta.marginLeft,
    },
    title: {
      text: chartMeta.title,
      style: getChartSubTitleStyle(chartMeta.theme),
      align: 'left',
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        pointPadding: 0.25,
        borderWidth: 0,
        color: '#000',
        targetOptions: {
          width: '200%',
        },
        animation: false,
      },
      bullet: {
        targetOptions: {
          width: '220%',
          height: 4,
          color: '#000',
        },
      },
    },
    xAxis: {
      categories: chartMeta.categories,
      labels: {
        style: getChartLegendStyle(chartMeta.theme),
      },
    },
    yAxis: {
      gridLineWidth: 0,
      plotBands: chartMeta.plotBands,
      labels: {
        format: '{value}%',
        style: getChartLegendStyle(chartMeta.theme),
      },
      title: '',
      minTickInterval: 1,
      allowDecimals: false,
    },
    series: chartMeta.series,
    tooltip: {
      formatter() {
        const oThis: any = this;
        const target = oThis.points[0].point.target;
        const y = oThis.y;
        return '<b>' + y + '%</b> (with target of ' + target + '%)';
      },
      followPointer: true,
      enabled: true,
      shared: true,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: chartMeta.exportingEnabled,
      sourceWidth: 1400,
      allowHTML: true,
      useMultiLevelHeaders: false,
      buttons: {
        contextButton: {
            menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadSVG', 'downloadPDF'],
        },
      },
      chartOptions: getExportingChartOptions(),
    },
  };
  // console.log(JSON.stringify(bulletGraphData));
  return bulletGraphData;
}

export function getLineChartFromMeta(chartMeta: LineChartMeta) {
  const lineData: any = {
    chart: {
      type: 'spline',
      height: chartMeta.height,
      style: getChartStyle(chartMeta.theme),
      backgroundColor: getChartBackgroundColor(chartMeta.theme),
      animation: false,
    },
    title: {
      text: chartMeta.title,
      style: getChartTitleStyle(chartMeta.theme),
      align: 'left',
    },
    legend: chartMeta.legendEnabled ? {
      itemStyle: getChartLegendStyle(chartMeta.theme),
      align: 'left',
    } : {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: chartMeta.xAxis,
        style: getChartLegendStyle(chartMeta.theme),
      },
      type: chartMeta.xAxisType,
      dateTimeLabelFormats: {
        day: '%e-%b',
        week: '%e-%b',
        month: '%b',
        year: '%b',
      },
      labels: {
        style: getChartLegendStyle(chartMeta.theme),
      },
    },
    yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: chartMeta.yAxis,
          style: getChartLegendStyle(chartMeta.theme),
        },
        labels: {
          style: getChartLegendStyle(chartMeta.theme),
        },
    },
    tooltip: {
      // This is not working for some reason. Should contact Highcharts.
      pointFormat: '{series.name}: <b>{point.y}</b><br/>',
      xDateFormat: '%m-%d',
      shared: true,
      valuePrefix: '$',
    },
    plotOptions: {
        series: {
          lineWidth: 4,
          label: {
              connectorAllowed: false,
          },
          marker: {
            enabled: false,
          },
          animation: false,
        },
    },
    colors: chartMeta.colors.length > 0 ? chartMeta.colors : colors.colors,
    series: chartMeta.series,
    formatNumbersToCurrency: chartMeta.formatNumbersToCurrency,
    exporting: {
      enabled: true,
      sourceWidth: 1400,
      allowHTML: true,
      useMultiLevelHeaders: false,
      chartOptions: getExportingChartOptions(),
    },
  };
  return lineData;
}

export function getLine(title: string, yAxisTitle: string, xAxisTitle: string, categories: any, series: any, tooltipPrefix: string, formatNumbersToCurrency: boolean = true) {
  return {
    chart: {
      style: getChartStyle(),
      animation: false,
    },
    title: {
      text: title,
      style: getChartTitleStyle(),
    },
    yAxis: {
        title: {
          text: yAxisTitle,
          style: getChartLegendStyle(),
        },
        labels: {
          style: getChartLegendStyle(),
        },
    },
    xAxis: {
      title: {
        text: xAxisTitle,
        style: getChartLegendStyle(),
      },
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e-%b-%Y',
        week: '%e-%b-%Y',
        month: '%b-%Y',
      },
      /*
      categories: categories,
      */
      labels: {
        style: getChartLegendStyle(),
      },
    },
    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        itemStyle: getChartLegendStyle(),
    },
    credits: {
      enabled: false,
    },
    colors: colors.colors,
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b><br/>',
        valuePrefix: tooltipPrefix,
        shared: true,
    },
    plotOptions: {
        series: {
            label: {
                connectorAllowed: false,
            },
            marker: {
                enabled: false,
            },
            animation: false,
        },
    },
    series,
    responsive: {
        rules: [{
            condition: {
                maxWidth: 500,
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                },
            },
        }],
    },
    formatNumbersToCurrency,
  };
}

export function getDonut(series: any, chartMeta: DonutMeta, dollarAmount = true) {
  const donutData: any = {
    chart: {
        backgroundColor: chartMeta.bgColor ? chartMeta.bgColor : '#FFFFFF',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: getChartStyle(),
        animation: false,
        height: '300px',
    },
    title: {
        text: chartMeta.title,
        style: getChartTitleStyle(),
    },
    credits: {
      enabled: false,
    },
    colors: colors.colors,
    legend: getDonutChartLegend(chartMeta),
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false,
            },
            showInLegend: true,
            animation: false,
            tooltip: {
              pointFormat: dollarAmount ? '<b>{point.y}</b> ({point.dollarAmount})<br/>' : '<b>{point.y}</b>',
              valueSuffix: '%',
              shared: false,
            },
        },
    },
    series,
    exporting: {
      enabled: chartMeta.exportingEnabled,
      allowHTML: true,
      useMultiLevelHeaders: false,
      chartOptions: getExportingChartOptions(),
    },
    formatNumbersToCurrency: chartMeta.formatNumbersToCurrency,
  };
  return donutData;
}

export function getPie(series: any, chartMeta: any, drilldown: any = [], drillup: any = [], formatNumbersToCurrency: boolean = true) {
  const pieData: any = {
  chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      style: getChartStyle(),
      animation: false,
  },
  title: {
      text: chartMeta.chartTitle,
      style: getChartTitleStyle(),
  },
  credits: {
    enabled: false,
  },
  colors: colors.colors,
  tooltip: {
      pointFormat: '<b>{point.percentage:.1f}% (${point.y})</b>',
  },
  legend: {
    itemStyle: getChartLegendStyle(),
  },
  plotOptions: {
      pie: {
          /*allowPointSelect: true,*/
          cursor: 'pointer',
          dataLabels: {
              enabled: false,
          },
          showInLegend: true,
          animation: false,
      },
  },
  series,
  exporting: {
    enabled: true,
    allowHTML: true,
    useMultiLevelHeaders: false,
    chartOptions: getExportingChartOptions(),
  },
  chartMeta,
  drilldown: {
    drillUpButton: {
      theme: {
      },
    },
  },
  formatNumbersToCurrency,
  };

  if (drilldown || drillup) {
    pieData.chart.events = {
      drilldown,
      drillup,
    };
  }

  return pieData;
}

// Force a window resize event to ensure correct layout of charts.
export function resize(timeout: number = 0) {
  setTimeout(() => {
    if (typeof (Event) === 'function') {
        window.dispatchEvent(new Event('resize'));
    } else {
        const event = document.createEvent('Event');
        event.initEvent('resize', true, true);
    }
  }, timeout);
}

export function getUpdateArgs(): any {
  return [true, true, {duration: 100}];
}

