




























































import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import { TotalsItem } from '../store/models';
import { getIconUrl } from '@/lib/images';
import TooltipComponent from "../components/TooltipComponent.vue";

@Component({
  components: {
    TooltipComponent,
  },
})
export default class TotalsItemComponent extends BaseComponent {

  @Prop() public totalsItem: any;
  @Prop() public tooltipText: any;
  @Prop() public reportMode: any;

  protected mounted() {
  }

  private getContainerCssClass(item: TotalsItem|null = null): string {
    const totalsItem = item ? item : this.totalsItem as TotalsItem;
    return totalsItem.hasContainer ? ' totals-item-container' :
      totalsItem.isFirst ? ' totals-item-first' :
      totalsItem.isLast ? ' totals-item-last' : ' totals-item-middle';
  }

  private getCssClass(): string {
    return this.totalsItem.cssClass === '' ? 'totals-item' : this.totalsItem.cssClass;
  }

  private getFlexbox(item: TotalsItem): string {
    return ' ' + ((item.isFullWidth) ? 'xs12' : ('xs12 sm' + (item.rowLayout ? item.rowLayout : '6')));
  }

  private getContainerStyle(item: TotalsItem): string {
    return 'background-color: ' + item.backgroundColor + ';color: ' + item.color;
  }

  private getStyle(item: TotalsItem): string {
    return 'color: ' + item.color + ';';
  }

  private getTooltipIcon(): string {
    return getIconUrl('tooltip.png');
  }
}
