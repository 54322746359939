import { formatCurrencyToString } from './shared';

export default function loadDataTableToggle(highcharts) {
    highcharts.setOptions({
        lang: {
            viewData: 'Toggle data table'
        }
    });
    highcharts.Chart.prototype.viewData = function () {
        if (!this.hasLoaded && this.userOptions.exporting.showTable) {
            this.hasLoaded = true;
            return
        }
        if (!this.insertedTable) {
            var div = document.createElement('div');
            div.className = 'highcharts-data-table';
            // Insert after the chart container
            this.renderTo.parentNode.insertBefore(div, this.renderTo.nextSibling);
            div.innerHTML = this.getTable();
            // Format the numbers to currency values if set in the userOptions.
            if (this.userOptions.formatNumbersToCurrency) {
                div.querySelectorAll('.number').forEach(function(element) {
                    element.innerHTML = formatCurrencyToString(element.innerHTML);
                });
            }
            this.insertedTable = true;
            var date_str = new Date().getTime().toString();
            var rand_str = Math.floor(Math.random() * (1000000)).toString();
            this.insertedTableID = 'div_' + date_str + rand_str;
            div.id = this.insertedTableID;
        }
        else {
            const dataTable = document.querySelector('#' + this.insertedTableID);
            dataTable.remove();
            this.insertedTable = false;
            this.insertedTableID = '';
        }
    };
}
