










import { Vue, Component, Prop } from 'vue-property-decorator';
import { noDataText } from '../lib/autocomplete';
import { handleError } from '../lib/shared';
import * as charts from '../lib/charts';
import * as _ from 'lodash';

// High Charts
import {Chart} from 'highcharts-vue';
import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import loadExporting from 'highcharts/modules/exporting';
import loadExportData from 'highcharts/modules/export-data';
import loadDrilldown from 'highcharts/modules/drilldown';

loadDrilldown(Highcharts);
loadExporting(Highcharts);
loadExportData(Highcharts);
HighchartsNoData(Highcharts);

@Component({
  components: {
    highcharts: Chart,
  },
})
export default class SimpleChartComponent extends Vue {

  @Prop() public value: any;
  @Prop() public chartId: any;
  @Prop() public theme: any;

  private updateArgs: any = charts.getUpdateArgs();

  private getChartClass() {
    switch (this.theme) {
      case 'light':
        return 'chart-container';
      case 'dark':
        return 'dark-chart-container';
      case 'social':
        return 'social-chart-container';
      default:
        return 'chart-container';
    }
  }
}
